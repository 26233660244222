<template>
  <validation-observer ref="simpleRules">
    <div>
      <loader
        v-if="loading"
        object="#009bc2"
        color1="#ffffff"
        color2="#1763fd"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        disable-scrolling="false"
        name="circular"
      />
      <!-- <this-header /> -->

      <div class="anan-set-nav-conttainer mt-150">
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-12">
              <side-bar class="d-none d-md-block" />
            </div>
            <div class="col-md-10 col-lg-10 col-sm-12">
              <h3>{{ $t("personalInfo") }}</h3>

              <div class="dashboard mt-3">
                <div
                  class="my-profile"
                  data-spm="1"
                  data-spm-anchor-id="a2o42.my_profile.0.1.170f392c5LLLNA"
                >
                  <div>
                    <img
                      v-if="items.profileimg"
                      :src="items.profileimg"
                      alt="Preview"
                      class="img-thumbnail profile-img shadow-sm"
                      @click="showPicture(items.profileimg)"
                    >
                    <img
                      v-else
                      :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
                      alt="Preview"
                      class="img-thumbnail profile-img shadow-sm"
                    >

                    <div class="profile-name">
                      <h3>{{ items.username }}</h3>

                      <b-badge
                        v-if="items.verify_status === 2"
                        variant="success"
                      ><feather-icon icon="CheckIcon" />
                        {{ $t('account') }}
                      </b-badge>
                      <b-badge
                        v-if="items.verify_status === 1"
                        variant="warning"
                      >
                        <span class="text-dark">{{ $t('pendingReview') }} Account </span>
                      </b-badge>

                      <b-badge
                        v-if="items.verify_status === 0"
                        variant="danger"
                      ><feather-icon icon="XIcon" />
                        {{ $t('key-195') }} Account
                      </b-badge>
                      <!-- <small
                        v-if="items.verify_status === 0"
                        v-b-modal.modal-verifyAccount
                        class="cursor-pointer"
                      >
                        ({{ $t('key-196') }})</small> -->

                      <b-button
                        v-if="items.verify_status === 0"
                        v-b-modal.modal-verifyAccount
                        size="sm"
                        pill
                        variant="gradient-success"
                        style="margin-left: 5px; padding: 3px 6px 3px 6px;"
                      >
                        {{ $t('key-196') }}
                      </b-button>
                    </div>

                    <div class="row mt-3">
                      <div class="col-lg-6">
                        <!-- <div
                        class="profile-list profile-list-red cursor-pointer"
                        @click="verifyBox"
                      >
                        <div class="title text-danger">
                          <img src="https://cdn-icons-png.flaticon.com/512/7222/7222916.png">  ยืนยันตัวตน
                        </div>
                        <h4>กด</h4>
                      </div> -->

                        <div class="profile-list">
                          <div class="title">
                            <img src="/icon/user.png"> {{ $t("key-77") }}
                          </div>
                          <span>{{ items.fullname }}</span>
                        </div>

                        <div class="profile-list">
                          <div class="title">
                            <img src="/icon/location.png"> {{ $t('key-197') }}
                          </div>
                          <span v-if="items.zipcode">{{ items.address }} {{ items.subdistrict_name }} {{
                            items.district_name }}
                            {{ items.province_name }} {{ items.zipcode }}
                          </span>
                          <span v-else>-</span>
                        </div>
                        <div class="profile-list">
                          <div class="title">
                            <img src="/icon/phone.png"> {{ $t("key-81") }}
                          </div>
                          <span>{{ items.tel ? items.tel : (items.tel2 ? items.tel2 : '-') }}</span>
                        </div>
                        <div class="profile-list">
                          <div class="title">
                            <img src="/icon/line.png"> ID LINE
                          </div>
                          <span>{{ items.line_id ? items.line_id : '-' }}</span>
                        </div>
                        <div class="profile-list">
                          <div class="title">
                            <img src="/icon/wechat.png"> ID WeChat
                          </div>
                          <span>{{ items.wechat_id ? items.wechat_id : '-' }}</span>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="profile-list">
                          <div class="title">
                            <img src="/icon/bookbank.png"> {{ $t('bankAccount') }}
                            {{ $t('key-198') }}
                          </div>

                          <div
                            v-for="item in itemBank"
                            :key="item"
                          >
                            <hr>
                            <span>{{ $t("accountName") }} :
                              {{ item.name_account }}
                              <b-badge
                                v-if="item.status === 2"
                                variant="success"
                              ><feather-icon icon="CheckIcon" />
                                {{ $t('key-199') }}
                              </b-badge>

                              <b-badge
                                v-if="item.status === 1"
                                variant="warning"
                                class="text-dark"
                              >
                                {{ $t('key-200') }}
                              </b-badge>
                              <b-badge
                                v-if="!item.status"
                                variant="danger"
                              > <feather-icon icon="XIcon" />
                                {{ $t('key-201') }}
                              </b-badge>
                              <b-badge
                                v-if="item.status === 3"
                                variant="secondary"
                              > <feather-icon icon="XIcon" />
                                {{ $t('key-202') }}
                              </b-badge>
                              <b-button
                                v-if="!item.status || item.status === 3"
                                v-b-modal.modal-verifyBank
                                size="sm"
                                pill
                                variant="gradient-success"
                                style="margin-left: 5px; padding: 3px 6px 3px 6px;"
                                @click="verifyBank(item)"
                              >
                                {{ $t('key-203') }}
                              </b-button>

                            </span><br>
                            <span>{{ $t('key-204') }} : {{ item.name_bank }}</span><br>
                            <span>{{ $t("key-48") }} :
                              {{ item.number_account }}</span>
                            <feather-icon
                              v-if="!item.status || item.status === 3"
                              v-b-modal.modal-1
                              icon="Edit3Icon"
                              class="ml-1 text-primary cursor-pointer"
                              @click="EditType(item)"
                            />
                            <feather-icon
                              v-if="!item.status || item.status === 3"
                              class="ml-1 text-danger cursor-pointer"
                              icon="TrashIcon"
                              @click="DeleteData(item)"
                            />
                          </div>
                          <div
                            v-if="itemBank.length < 2"
                            class="d-flex justify-content-end"
                          >
                            <b-button
                              v-b-modal.modal-1
                              size="sm"
                              class="btn-gradient-success mt-1"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                class="mr-10"
                              />
                              {{ $t("addInfo") }}
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-end">
                      <div>
                        <button
                          class="btn btn-gradient-primary"
                          @click="editPassword()"
                        >
                          <i class="far fa-edit" /> {{ $t("setPassword") }}
                        </button>
                      </div>
                      <div>
                        <b-link :to="{ name: 'profile-edit', params: { id: items.id } }">
                          <button class="btn btn-gradient-primary ml-1">
                            <i class="far fa-wrench" />
                            {{ $t("editPersonalInfo") }}
                          </button>
                        </b-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
        <b-modal
          id="modal-1"
          cancel-variant="outline-secondary"
          :ok-title="$t('key-124')"
          :cancel-title="$t('cancel')"
          centered
          :title="$t('addBankAccount')"
          @ok.prevent="addNewbank()"
        >
          <validation-observer ref="addNewbank">
            <b-form>
              <b-form-group>
                <span class="text-danger">*</span> {{ $t("bankAccount") }} :
                <v-select
                  v-model="bank_obj"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="itemBankList"
                >
                  <template #option="{ name, path_photo }">
                    <img
                      :src="`${path_photo}`"
                      :alt="name"
                      height="30"
                      class="mr-50 rounded-sm"
                    >
                    <span>{{ name }}</span>
                  </template>

                  <template #selected-option="{ name, path_photo }">
                    <img
                      :src="`${path_photo}`"
                      :alt="name"
                      height="30"
                      class="mr-50"
                    >
                    <span>{{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
              <b-form-group>
                <span class="text-danger">*</span> {{ $t("accountName") }} :
                <validation-provider
                  #default="{ errors }"
                  name="name_account"
                  rules="required"
                >
                  <b-form-input
                    id="h-name_bank"
                    v-model="name_account"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("requiredField") + "!" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <span class="text-danger">*</span> {{ $t("accountNumber") }} :
                <validation-provider
                  #default="{ errors }"
                  name="number_account"
                  rules="required"
                >
                  <b-form-input
                    id="h-number_account"
                    v-model="number_account"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("requiredField") + "!" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group>
          {{ $t('branch') }} :
          <b-form-input type="text" />
        </b-form-group> -->
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
    </div>

    <b-modal
      id="modal-verifyAccount"
      cancel-variant="outline-secondary"
      :ok-title="$t('key-124')"
      :cancel-title="$t('cancel')"
      centered
      :title="$t('key-196')"
      @ok.prevent="verifyAccount()"
    >

      <div class="w-100 h-100">
        <div class="d-flex justify-content-center flex-wrap">
          <img
            src="/IDCard1.png"
            width="300px"
          >
          <img
            src="/IDCard2.png"
            width="300px"
          >
        </div>

        <!-- <b-form-file
            v-model="file_cus"
            accept=".jpg, .png, .jpeg"
            placeholder="แนบรูปบัตรประชาชน"
            drop-placeholder="Drop file here..."
            @change="fileImage"
          /> -->
        <div class="d-flex justify-content-center align-items-center">
          <input
            ref="fileInput"
            type="file"
            accept="image/*"
            style="display: none"
            @change="fileImage"
          >
          <input
            ref="cameraInput"
            type="file"
            accept="image/*"
            capture="camera"
            style="display: none"
            @change="fileImage"
          >
          <b-button
            style="width: auto; height: 100px"
            variant="relief-primary"
            class="mr-1"
            @click="openFileInput"
          >
            <feather-icon
              icon="FilePlusIcon"
              class="mb-1"
              size="30"
            /><br>{{ $t('key-205') }}
          </b-button>
          {{ $t("or") }}
          <b-button
            style="width: auto; height: 100px"
            variant="relief-success"
            class="ml-1"
            @click="openCamera"
          >
            <feather-icon
              icon="CameraIcon"
              class="mb-1"
              size="30"
            /><br>
            {{ $t('key-206') }}
          </b-button>
        </div>
        <div class="mt-2 d-flex justify-content-center">
          <span
            v-if="imageOr"
            class="preview-image-containerv2 mr-2 d-flex justify-content"
          >
            <img
              :src="imageOr"
              alt="Preview"
              class=""
              @click="showPicture(imageOr)"
            >
            <div class="preview-image-overlays p-0">
              <i
                class="fas fa-times-circle cursor-pointer"
                @click="deleteImageorder()"
              />
            </div>
          </span>
        </div>
      </div>

    </b-modal>
    <b-modal
      id="modal-verifyBank"
      cancel-variant="outline-secondary"
      :ok-title="$t('key-124')"
      :cancel-title="$t('cancel')"
      centered
      :title="$t('key-207')"
      @ok.prevent="SubmitVerifyBank()"
    >

      <div class="w-100 h-100">
        <div class="d-flex justify-content-center flex-wrap">
          <img
            src="/Bank2.png"
            width="100%"
          >

          <img
            src="/Bank1.png"
            width="100%"
          >
          <h3 class="font-weight-bold text-danger text-center">
            *ชื่อบัญชี และบัตรประชาชน ต้องตรงกันเท่านั้น
          </h3>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <input
            ref="fileInputBank"
            type="file"
            accept="image/*"
            style="display: none"
            @change="fileImageBank"
          >
          <input
            ref="cameraInputBank"
            type="file"
            accept="image/*"
            capture="camera"
            style="display: none"
            @change="fileImageBank"
          >
          <b-button
            style="width: auto; height: 100px"
            variant="relief-primary"
            class="mr-1"
            @click="openFileInputBank"
          >
            <feather-icon
              icon="FilePlusIcon"
              class="mb-1"
              size="30"
            /><br><div style="width: 100px;">
              {{ $t('key-208') }}
            </div>
          </b-button>
          {{ $t("or") }}
          <b-button
            style="width: auto; height: 100px"
            variant="relief-success"
            class="ml-1"
            @click="openCameraBank"
          >
            <feather-icon
              icon="CameraIcon"
              class="mb-1"
              size="30"
            /><br>
            <div style="width: 100px;">
              {{ $t('key-209') }}
            </div>
          </b-button>
        </div>

        <div class="mt-2 d-flex justify-content-center">
          <span
            v-if="imageOrBank"
            class="preview-image-containerv2 mr-2 d-flex justify-content"
          >
            <img
              :src="imageOrBank"
              alt="Preview"
              class=""
              @click="showPicture(imageOrBank)"
            >
            <div class="preview-image-overlays p-0">
              <i
                class="fas fa-times-circle cursor-pointer"
                @click="deleteImageorderBank()"
              />
            </div>
          </span>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="edit-pass"
      :title="$t('key-162')"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      @ok.prevent="submitPassword()"
    >
      <validation-observer ref="addRules">
        <b-form-group
          label-for="h-name"
          label="Password"
        >
          <validation-provider
            #default="{ errors }"
            name="Password"
            rules="required"
          >
            <b-form-input
              id="h-name"
              v-model="password"
              type="password"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-name"
          label="Confirm Password"
        >
          <validation-provider
            #default="{ errors }"
            name="Confirm Password"
            rules="required|confirmed:Password"
          >
            <b-form-input
              id="h-name"
              v-model="confirmPassword"
              type="password"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </validation-observer>
</template>

<script>
import {
  BBadge,
  BButton,
  BFormFile,
  BFormGroup,
  BFormInput,
  BLink,
  BModal,
  BOverlay,
  // VBtn,
} from 'bootstrap-vue'

// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { email, required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import SideBar from './component/SideBar.vue'

export default {
  name: 'CustomerNewView',
  components: {
    // ThisHeader,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BModal,
    BLink,
    // BFormCheckbox,
    SideBar,
    BBadge,
    BButton,
    vSelect,
    BFormFile,
    BOverlay,
    VueImageLightboxCarousel,
    // VBtn,
    // BListGroup,
    // BListGroupItem,
    // BImg,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      email,
      itemBank: [],
      itemBankList: [],
      bank_obj: null,
      name_account: null,
      number_account: null,
      id: null,
      info: {},
      selected: [],
      fullName: '',
      password: '',
      confirmPassword: '',
      items: {
        id: null,
        email: null,
        fullname: null,
        line_id: null,
        tel: null,
        username: null,
      },
      imageOr: null,
      imageOrBank: null,
      itemsbank: null,
      showOver: false,
    }
  },
  mounted() {
    this.getData()
    this.getSelect()
    this.getBank()
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click()
    },
    openCamera() {
      this.$refs.cameraInput.click()
    },
    deleteImageorder() {
      this.file_cus = null
      this.imageOr = null
    },
    verifyAccount() {
      if (!this.items.zipcode) {
        this.SwalError(this.$t('key-210'))
      } else if (!this.imageOr) {
        this.SwalError(this.$t('key-211'))
      } else {
        const obj = {
          file_profile: this.imageOr,
        }
        this.$http.post('/customer/VerifyInfomation', obj)
          .then(response => {
            if (response.data.status) {
              this.getData()
              this.Success(this.$t('1-2'))
              this.$bvModal.hide('modal-verifyAccount')
              this.imageOr = null
            }
          })
          .catch(err => {
            console.log(err)
            this.SwalError(err)
          })
      }
    },
    verifyBank(data) {
      if (data) {
        this.itemsbank = data
      } else {
        this.itemsbank = null
      }
      this.$bvModal.show('modal-verifyBank')
    },
    SubmitVerifyBank() {
      if (this.items.verify_status !== 2) {
        this.SwalError(this.$t('key-210'))
      } else if (!this.imageOrBank) {
        this.SwalError(this.$t('key-212'))
      } else {
        const obj = {
          id: this.itemsbank._id,
          status: 1,
          file_bank: this.imageOrBank,
        }
        this.$http.post('/Refill/StoreBank', obj)
          .then(response => {
            if (response.data.status) {
              this.getBank()
              this.Success(this.$t('1-2'))
              this.$bvModal.hide('modal-verifyBank')
              this.imageOrBank = null
            }
          })
          .catch(err => {
            console.log(err)
            this.SwalError(err)
          })
      }
    },
    fileImage(event) {
      if (event) {
        this.file_cus = event.target.files

        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.file_cus[0]
        if (!filepath) {
          this.imageOr = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.imageOr = e.target.result
            // eslint-disable-next-line no-undef

            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    openFileInputBank() {
      this.$refs.fileInputBank.click()
    },
    openCameraBank() {
      this.$refs.cameraInputBank.click()
    },
    deleteImageorderBank() {
      this.file_cusBank = null
      this.imageOrBank = null
    },
    fileImageBank(event) {
      if (event) {
        this.file_cusBank = event.target.files

        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.file_cusBank[0]
        if (!filepath) {
          this.imageOrBank = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.imageOrBank = e.target.result
            // eslint-disable-next-line no-undef

            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    async getBank() {
      this.showOver = true
      try {
        const { data: res } = await this.$http.get('/Refill/getBankcus')
        this.itemBank = res
        this.showOver = false
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    async getSelect() {
      try {
        const { data: res } = await this.$http.get('/bank')
        this.itemBankList = res.map(ele => ({
          id: ele.id,
          bankcode: ele.bankcode,
          name: ele.name,
          path_photo: `/bankIcon/${ele.path_photo}`,
        }))
      } catch (e) {
        console.log(e)
      }
    },
    async getPreProduct() {
      const { data } = await this.$http.get('/v1/product/pre-product')
      this.info = data
      this.ShowProduct = false
    },
    async getData() {
      this.showOver = true
      try {
        const { data: res } = await this.$http.get('/Profilecustomer/show')
        this.items = res
        this.showOver = false
        // res.forEach(ele => {
        //   this.items.id = ele.id
        //   this.items.email = ele.email
        //   this.items.fullname = ele.fullname
        //   this.items.line_id = ele.line_id
        //   this.items.tel = ele.tel
        //   this.items.username = ele.username
        // })
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('add-addr')
        }
      })
    },
    submitPassword() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const obj = {
            password: this.password,
          }
          this.$http.post('/users/UpdatePass', obj)
            .then(() => {
              this.Success(this.$t('key-213'))
              this.$bvModal.hide('edit-pass')
            })
            .catch(err => {
              this.SwalError(err)
              console.log(err)
            })
        }
      })
    },
    editPassword() {
      this.$bvModal.show('edit-pass')
    },
    showPicture(picture) {
      console.log(picture)
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: this.$t('key-214'),
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    EditType(data) {
      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this.id = data._id
        this.name_bank = data.name_bank
        this.name_account = data.name_account
        this.number_account = data.number_account
        this.branch = data.branch
        const FindBank = this.itemBankList.find(
          ele => ele.bankcode === data.bank_code,
        )
        this.bank_obj = FindBank
      } else {
        this.id = null
        this.name_bank = null
        this.name_account = null
        this.number_account = null
        this.branch = null
        this.bank_obj = null
      }
      this.$bvModal.show('modal-1')
    },
    addNewbank() {
      this.$refs.addNewbank.validate().then(success => {
        if (success) {
          const obj = {
            name_bank: this.bank_obj.name,
            bank_code: this.bank_obj.bankcode,
            name_account: this.name_account,
            number_account: this.number_account,
            branch: this.branch,
          }
          this.$http
            .post('/Refill/StoreBank', obj)
            .then(() => {
              this.$bvModal.hide('modal-2')
              this.Success(this.$t('key-215'))
              this.getBank()
              this.hideModal()
              this.id = null
              this.name_bank = null
              this.name_account = null
              this.number_account = null
              this.branch = null
              this.bank_obj = null
            })
            .catch(err => {
              this.SwalError(err.response.data.message)
              console.log(err)
            })
        }
      })
      // console.debug(this.bank_obj)
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm(this.$t('key-216'), {
          title: this.$t('key-217'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(result => {
          if (result) {
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
            }
            this.$http
              .post('/Refill/DeleteBank', obj)
              .then(() => {
                this.getBank()
                this.Success(this.$t('key-218'))
              })
              .catch(err => {
                console.log(err)
                this.SwalError(err)
              })
          }
        })
    },
    hideModal() {
      this.$bvModal.hide('modal-1')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-219')}!</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@media only screen and (max-width: 600px) {
  .btn {
    font-size: 12px !important;
  }
}

.profile-img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -20px;
}

.profile-name {
  margin-top: -20px;
  margin-left: 110px;
}

.profile-list {
  // margin-top: 5px;
  margin-bottom: 30px;
  padding: 15px;
  box-shadow: 0px 0px 5px rgb(197, 197, 197);
  border-radius: 5px;
}

.profile-list-red {
  // margin-top: 5px;
  margin-bottom: 30px;
  padding: 15px;
  box-shadow: 0px 0px 5px rgb(255, 0, 0) !important;
  border-radius: 5px;
}

.profile-list .title {
  position: absolute;
  margin-top: -27px;
  font-size: 15px;
  font-weight: 900;
  color: #002164;
  background-color: #fff;
  pointer-events: none;
}

.profile-list .add-account {
  position: absolute;
  margin-top: -27px;
  margin-left: 320px;
  z-index: 99;
}

.profile-list .title img {
  position: relative;
  margin-top: -5px;
  margin-left: -10px;
  width: 30px;
}

.profile-list span {
  position: relative;
  margin-left: 10px;
  // margin-top:50px !important;
}

.profile-list span::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-image: url("/icon/right.png");
  opacity: 0.3;
  background-size: cover;
  margin-right: 5px;
}
</style>
